
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import { Watch } from 'vue-property-decorator';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Profile extends Vue {

    selectedLanguage: string = "";
    availableLanguages: OM.TextIdentifier[] = [];

    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();
    
    created() {
        this.loggedUser = StorageServices.getLoggedUser();
        this.selectedLanguage = this.loggedUser.preferredCulture;
        this.availableLanguages = this.$localizationService.getEnabledLanguageList();
    } 

    get registerBikeLink() {
        if(this.selectedLanguage == "it-IT")
            return "https://www.aprilia.com/it_IT/bearacerclub/profile/";
        else
            return "https://www.aprilia.com/en_EN/bearacerclub/profile/";
    }
    get eventProgramLink() {
        if(this.selectedLanguage == "it-IT")
            return "https://www.aprilia.com/it_IT/mondo-aprilia/events-aprilia/aprilia-all-stars-2024/";
        else
            return "https://www.aprilia.com/en_EN/aprilia-world/events-aprilia/aprilia-all-stars-2024/";
    }
    get communityLink() {
        if(this.selectedLanguage == "it-IT")
            return "https://www.aprilia.com/it_IT/bearacerclub/private-page/";
        else
            return "https://www.aprilia.com/en_EN/bearacerclub/private-page/";
    }

    @Watch('selectedLanguage')
    updateLanguage() {
        this.setLanguage();
    }

    setLanguage(){
        var loggedUser = store.state.loggedUser;
        loggedUser.preferredCulture = this.selectedLanguage;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

    logout() {
        LoginServices.logout();
    }

}
